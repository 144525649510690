import Layout from "../components/global/Layout"
import { Link } from "gatsby"
import React from "react"
// import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} title={"404: Not Found"} 
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "404",
        pageName: "404",
        brand: "grubhub"
      }}>
      <Container>
        {/* <StaticImage src="../../static/images/corporate-logo.webp" alt="Oh NO!"/> */}
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/">Go back home</Link>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

const Container = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  flex-flow:column;
  min-height:80vh;
`;